<template>
<div>
 
    <div class="row" v-if="mode=='small'">
        <div class="col-3"> 
            <img :src="previewPicture" style="max-height:200px;max-width:100%;"/>
        </div>
        <div class="col-9">
            <v-file-input outlined hide-details="" v-model="pictureUpload" id="file" ref="file" :label="label ? label : 'รูปภาพ'" filled prepend-icon="mdi-image" accept=".jpg,.jpeg,.png,.tif" class="ms"></v-file-input>
            <div class="mt-2 mb-2 uploadResult" v-if="uploadResult" v-html="uploadResult"></div>
            <div class="text-center" v-if="showLoading">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
            <div v-if="canDelete">
                <v-checkbox v-model="delete_picture" label="ลบรูปภาพ" color="error" value="A" hide-details></v-checkbox>
            </div>
        </div>
    </div>

    <div class="row" v-if="mode=='full'">
        <div class="col-12 text-center">
            <img :src="previewPicture" style="max-height:200px;max-width:100%;" />
        </div>
        <div class="col-12">
            <v-file-input outlined hide-details="" v-model="pictureUpload" id="file" ref="file" :label="label ? label : 'รูปภาพ'" filled prepend-icon="mdi-image" accept=".jpg,.jpeg,.png,.tif"></v-file-input>
            <div class="mt-2 mb-2 uploadResult" v-if="uploadResult" v-html="uploadResult"></div>
            <div class="text-center" v-if="showLoading">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
            <div v-if="canDelete">
                <v-checkbox v-model="delete_picture" label="ลบรูปภาพ" color="error" value="A" hide-details></v-checkbox>
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {

    components: {

    },
    props: {
        configUploadName: null,
        defaultPicture: null,

        mode: null,

        mode: {
            type: String,
            default: 'full'
        },

        canDelete: {
            type: Boolean,
            default: false
        },

        label: '',
    },

    data: () => ({
        pictureUpload: null,
        uploadResult: null,
        showLoading: null,
        delete_picture: null,
        previewPicture: null
    }),

    computed: {

    },

    watch: {
        defaultPicture(val) {
            if (val == 'reset') {
                this.pictureUpload = null
                this.previewPicture = this.$INFO.previewPicture
            } else {
                this.setDefaultPicture()
            }

        },

        delete_picture() {
            this.file = null,
                console.log(this.delete_picture)
            this.$emit('pictureDelete', this.delete_picture)

        },
        pictureUpload: function (val) {

            let $vm = this
            $vm.showLoading = true

            var url = "misc/pictureOne?time=" + Math.random();

            let data = {
                file: val,
                configUploadName: this.configUploadName
            }

            this.$api(url, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then(function (data) {
                if (data.uploadResult) {
                    let re = data.uploadResult
                    if (re.result == 'success') {
                        console.log("SUCCESS!!");
                        $vm.previewPicture = re.previewPicture

                        $vm.$emit('pictureName', re.picture)
                    }
                    $vm.uploadResult = re.msg
                }
                $vm.showLoading = null;
            })
        }

    },
    methods: {

        setDefaultPicture() {

            if (!this.defaultPicture) {
                this.previewPicture = this.$INFO.previewPicture;
            } else {
                this.previewPicture = this.defaultPicture
            }
        }

    },
    created() {

        this.setDefaultPicture()

    },

};
</script>
